<template>
  <div>
    <div class="d-flex justify-content-center align-items-center mb-5 mt-2">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        size="lg"
        class="big-button"
        variant="primary"
      >
        客户取件
      </b-button>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        class="ml-5 big-button"
        size="lg"
        variant="success"
      >
        海运入库
      </b-button>
    </div>
    <b-card
      class="text-center"
    >
      <div class="card-wrap">
        <h1>请输入取货码</h1>
        <div class="number-box">
          <sub-code ref="subCode" />
        </div>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          size="lg"
          @click="handleSubmit"
        >
          确定
        </b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SubCode from '../agent/components/code.vue'

export default {
  components: {
    SubCode,
    BCard,
    BButton,
  },
  directives: {
    Ripple,
  },
  created() {
    this.getUserInfo()
  },
  mounted() {
    document.onkeydown = () => {
      const key = window.event.keyCode
      if (key === 13) {
        this.handleSubmit()
      }
    }
  },
  methods: {
    async getUserInfo() {
      try {
        const { data } = await this.$api.getUser()
        localStorage.setItem('userInfo', JSON.stringify(data))
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('error', error)
      }
    },
    handleSubmit() {
      const subCode = this.$refs.subCode.code
      if (subCode.length === 6) {
        this.$router.push({ name: 'seatranspickdetail' })
      } else {
        this.$bvModal
          .msgBoxConfirm('请输入六位预报单号', {
            cancelVariant: 'outline-danger',
            centered: true,
            buttonSize: 'sm',
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.card-wrap{
  padding: 100px 0;
}
.number-box{
  margin: 200px;
}
.big-button{
  width: 200px;
  height: 90px;
}
</style>
